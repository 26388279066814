import { FC } from "react";
import { ToolbarLinkItemProps } from "../types";
import styled from 'styled-components/'

const LinkItem: FC<ToolbarLinkItemProps> = (props) => {
  const activeClassName = props.active ? "active" : "";

  return (
    <li>
      <StyledLink href={props.to} onClick={() => props.onClick?.call(this)} className={`block py-2 px-1 rounded-md ${activeClassName}`}
        target={props.target ?? "_self"} rel={props.rel ?? ""}
      >
        {props.label}
      </StyledLink>
    </li>
  );
};

const StyledLink = styled.a`
  color: ${prop => prop.className?.includes('active') ? 'rgb(250, 202, 21)' : 'rgb(209 213 219 / 0.90)'};
  cursor: pointer;
  
  :hover {
    color: rgb(253 230 138);
  }
  
  
`;

export default LinkItem;