import { FC } from "react";
import styled from 'styled-components';
import { Button } from "../../styles/style.button";

const NotFoundPage: FC = () => {
  return (
    <NotFoundPageWrapper className="flex place-items-center justify-center bg-gradient-to-br from-purple-700 to-purple-500">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-yellow-300">404</h1>
                <p className="mb-12 text-2xl tracking-tight font-bold text-yellow-300 md:text-3xl">Ooops! Algo se perdeu no caminho</p>
                <p className="mb-4 text-lg font-light text-white">Desculpe, não conseguimos encontrar a página solicitada. Você encontrará muito para explorar na página principal.</p>
                
                <Button href="/" className="inline-flex my-4">Voltar para Home</Button>
            </div>   
        </div>
    </NotFoundPageWrapper>
    );
};

const NotFoundPageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  /* background-color: rgb(226 232 240); */
`

export default NotFoundPage;