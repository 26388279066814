
import React, { SyntheticEvent, useEffect, useRef } from "react";
import { createRoot, Root } from "react-dom/client";
import { RiCloseLine } from "react-icons/ri";
import styled from "styled-components";

const TOAST_CONTAINER_ID = 'toast' as const;
const TOAST_TIME_MS = 4500 as const;

let toastRoot: Root|null = null;
let toastContainer: Element | null = null;
let toastTimeout: number = -1;

type CodeToastProps = {
    message: string;
    timer?: number;
};

export const ShowToast = (props: CodeToastProps, documentId: string = TOAST_CONTAINER_ID) => {
    toastContainer = document.getElementById(documentId) as Element;
    let canRender = !toastContainer || !toastRoot; 

    if (!toastContainer) {
        toastContainer = document.createElement('div');
        toastContainer.setAttribute('id', documentId);
        toastContainer.setAttribute('class', "absolute bottom-5 right-5 z-index-top");
        document.body.appendChild(toastContainer);
    }

    if (!toastRoot) {
        toastRoot = createRoot(toastContainer);
    }

    if (!canRender) {
        window.clearTimeout(toastTimeout);
        toastTimeout = window.setTimeout(HideToast, props?.timer ?? TOAST_TIME_MS);
        return;
    }

    toastRoot?.render(<CodeToast {...props} />);
}

export const HideToast = () => {
    toastRoot?.unmount();
    toastRoot = null;
    toastContainer = null;
}

const CodeToast: React.FC<CodeToastProps> = (props) => {
    
    const toast = useRef<HTMLOutputElement>(null);

    const onReset = () => {
        window.clearTimeout(toastTimeout);
    }

    const onStart = () => {
        toastTimeout = window.setTimeout(HideToast, props?.timer ?? TOAST_TIME_MS);
    }

    const onClose = (event: SyntheticEvent | null = null) => {
        if (event) {
            event.preventDefault();
        }

        HideToast();
    }

    useEffect(() => {
        onStart();
        toast.current?.focus();

        return () => { onClose(); }
    })

    return (
        <output onMouseLeave={onStart}
            onMouseEnter={onReset}
            ref={toast}>
            <StyledToast >
                <span className="text-sm font-normal">{props?.message}</span>
                <button onClick={onClose}><RiCloseLine className="icon" /></button>
            </StyledToast>
        </output>
    );
}

const StyledToast = styled.div`
    display: flex;
    background-color: rgba(17, 24, 39, 1);
    padding: 0.75rem 1rem;
    color: white;
    word-wrap: break-word;
    border-radius: 4px;
    justify-items: center;
    align-items: center;
    max-width: 25rem;

    button {
        padding: 2.5px;
        border-radius: 50%;
        color: white;
        margin-left: 0.35rem;
    }

    button:hover, button:focus {
        background-color: rgba(51, 65, 85, 1);
    }

    button > .icon {
        color: white;
        height: 1.25rem;
        width: 1.25rem;
    }
`;

export default CodeToast;