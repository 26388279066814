import { FC } from "react";
import { HiMenuAlt3 } from 'react-icons/hi'
import { ToolbarToogleProps } from "../types";

export const ToolbarToogle : FC<ToolbarToogleProps> = (props) => {
    return (        
        <div className="flex items-center lg:order-3">
            <button type="button" onClick={() => props.onToogle?.call(this)} className="inline-flex items-center p-2 ml-1 text-sm text-gray-50 rounded-lg lg:hidden hover:text-gray-400 focus:outline-none focus:text-gray-200">
                <span className="sr-only">Open main menu</span>
                <HiMenuAlt3 className="w-5 h-5" />
            </button>
        </div>
    )
}