import { FC } from "react";
import {
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import { Divider } from "../../styles/style.divider";

export const FooterSection: FC = () => {
  const currentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer>
      <Divider/>

      <div className="items-center pt-10 mx-auto max-w-screen-xl">
        <div className="md:flex md:items-center md:justify-between">
          
        <div className="flex mt-4 space-x-6 sm:mt-0 justify-center">
          <a
            href="https://petsaviors.com.br/"
          >
            <img src="https://storage.codeinformatica.com.br/petsaviors-logo.webp?v=1" alt="Petsaviors Tecnologia" 
              height={100} width={200} />
          </a>
        </div>

          <div className="flex mb-8 md:mb-0 md:block md:justify-self-auto justify-center place-items-center items-center text-center">           
            <span className="text-sm text-gray-200/75 sm:text-center">
              <br />
              {currentYear()}
              {" © "}
              <a
                href="https://petsaviors.com.br"
                className="hover:underline font-semibold text-amber-400"
              >
                Petsaviors Tecnologia LTDA™
              </a>
            </span>
          </div>

          <div className="flex mt-4 space-x-6 sm:mt-0 justify-center">
            <a
              href="https://www.instagram.com/petsaviors.tecnologia/"
              rel="noreferrer"
              target="_blank"
              className="text-purple-700 rounded-full bg-amber-400 p-3 hover:bg-pink-600 hover:text-white"
            >
              <FaInstagram />
            </a>
            
            <a
              href="https://wa.me/send?phone=5531971361592&lang=pt_br"
              rel="noreferrer"
              target="_blank"
              className="text-purple-700 rounded-full bg-amber-400 p-3 hover:bg-green-500 hover:text-white"
            >
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </div>

      <div className="flex py-8 mt-10 w-full justify-center items-center text-center">
          <a
            href="https://inovatech.pnl.mg.gov.br/"
          >
            <img src="https://storage.codeinformatica.com.br/inova-white.webp?v=1" alt="Apoio - Inovatech e Prefeitura de Nova Lima" 
              width={200} />
          </a>
        </div>

    </footer >
  );
};
