import { FC, useState } from "react";
import { ToolbarBrand } from "./components/Brand";
import LinkItem from "./components/LinkItem";
import { ToolbarLinkItemList } from "./components/LinkItemList";
import { ToolbarToogle } from "./components/Toogle";
import { useLocation} from 'react-router-dom'
import { ToolbarProps } from "./types";

export const Toolbar: FC<ToolbarProps> = (props) => {
    const [mobileToogled, setMobileToogled] = useState<boolean>(false);
    const _className = `${mobileToogled ? '' : 'hidden'} justify-between items-center w-full lg:flex lg:w-auto lg:order-1 rounded-md px-4 py-2 mt-2`;
    const location = useLocation();

    const toogleContactForm = () => {
        setMobileToogled(false);
        props.onToogleContactForm?.call(this);
    }

    return (
    <header>
        <nav className="bg-purple-700 px-4 lg:px-6 py-3 fixed w-full">
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                <ToolbarBrand />

                <div className="flex">
                    <ToolbarToogle onToogle={() => setMobileToogled(!mobileToogled)}/>
                </div>

                <div className={_className}>
                    <ToolbarLinkItemList>
                        <LinkItem active={location.pathname === "/" && !location.hash} to="/" label="Home" />
                        <LinkItem onClick={toogleContactForm} label="Contato" />
                    </ToolbarLinkItemList>
                </div>
            </div>
        </nav>
    </header>  
  );
};
