import { FC } from "react";
import { BiQuestionMark } from "react-icons/bi";
import styled from "styled-components";
import { WhatsappButtonProps } from "./types";

const WhatsappButton: FC<WhatsappButtonProps> = (props) => {
  return (
    <StyledWrapper href="https://guiadepatas.com.br/"
      target="_blank" rel="noreferrer">
      <span className="title font-normal text-sm mr-3">Quero saber mais</span>

      <span className="cta">
        <BiQuestionMark width={5} height={5} />
      </span>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.a`
  position: fixed;
  width: auto;
  min-height: 35px;
  background: rgb(251, 191, 36);
  bottom: 0;
  right: 0;
  border-radius: 20px 0 0 20px;
  margin: 35px 54px;
  padding: 8px 12px;
  color: rgb(108, 43, 217);
  line-height: 65px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  z-index: 0;
  box-shadow: 0 10px 25px -5px rgba(251, 191, 36, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  :hover {
    background: rgb(255, 200, 100);
    .cta {
      background: rgb(108, 43, 217);
    }
  }

  .cta {
    position: fixed;
    height: 42px;
    width: 42px;
    right: 0;
    margin: 35px 28px;
    display: flex;
    text-align: center;
    color: rgb(251, 191, 36);
    border: 2px solid;
    border-color: rgb(251, 191, 36);
    align-items: center;
    justify-content: center;
    background-color: rgb(108, 43, 217);
    border-radius: 50%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
`;

export default WhatsappButton;
