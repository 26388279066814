import { FC } from 'react';

export const ToolbarBrand: FC = () => {
  return (
    <a href="https://petsaviors.com.br/" className="flex items-center">
      <img src={"https://storage.codeinformatica.com.br/petsaviors-logo.webp"} 
        className="mr-3 h-14" alt="Logo" />
    </a>
  );
};
