import { FC, useState } from "react";
import { Toolbar } from "../../../components/Toolbar";
import { LayoutProps } from "../types";
import WhatsappButton from "../../../components/WhatsAppButton";
import ContactForm from "../../../components/ContactForm";
import { ContactFormData, defaultFormData } from "../../../components/ContactForm/types/index.";

export const MainLayout: FC<LayoutProps> = (props) => {
  const [showContactForm, setShowContactForm] = useState<boolean>(false);


  const [sendingContactForm, setSendingContactForm] = useState<boolean>(false);
  const [contactFormModel, setContactFormModel] = useState<ContactFormData>(defaultFormData);

  return (
    <>
      <Toolbar
        onToogleContactForm={() => setShowContactForm(true)}
      />

      {props.children}

      <ContactForm
        show={showContactForm}
        sending={[sendingContactForm, setSendingContactForm]}
        formData={[contactFormModel, setContactFormModel]}
        onClose={() => {
          setShowContactForm(false);
          setSendingContactForm(false);
          setContactFormModel(defaultFormData);
        }}
      />

      <WhatsappButton onClick={() => setShowContactForm(true)} />
    </>
  );
};
