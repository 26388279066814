import { FC } from "react";
import { AboutSection } from "./sections/about";
import { HeaderSection } from "./sections/header";
import { FooterSection } from "../footer";
export const HomePage: FC = () => (
  <>
    <HeaderSection />
    <AboutSection />
    <FooterSection />
  </>
);
