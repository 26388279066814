import { FC } from "react";
import { ToolbarLinkItemListProps } from "../types";

export const ToolbarLinkItemList: FC<ToolbarLinkItemListProps> = (props) => {
  return (
    <ul className="flex flex-col font-medium lg:flex-row lg:space-x-8 mt-4 lg:mt-0">
      {props.children}
    </ul>
  );
};
