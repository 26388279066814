import styled from "styled-components";

export const Button = styled.a<{ $primary?: boolean; }>`
    background-color: rgb(251, 191, 36);
    color: rgb(108, 43, 217);
    border-radius: 9999px;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    padding-left: 1.25rem/* 20px */;
    padding-right: 1.25rem/* 20px */;
    padding-top: 0.625rem/* 10px */;
    padding-bottom: 0.625rem/* 10px */;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    :hover {
        background-color: rgb(144, 97, 249);
        color: rgb(255, 255, 255);
    }
`