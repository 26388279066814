import { FC } from "react";
import { Button } from "../../../../styles/style.button";

export const AboutSection: FC = () => {
    return (
        <section id="sobre" className="pb-8">
            <div className="px-4 pb-12 flex flex-col">
                <div className="place-self-center justify-items-center mt-10">
                    <h1 className="mb-16 max-w-4xl text-center text-4xl font-medium tracking-tight leading-none text-white">Junte-se a comunidade <span className="text-amber-400 font-bold">Guia de Patas</span> e faça você também a vida dos pets uma vida melhor</h1>

                    <div className="mt-5 flex justify-center">
                        <Button href="https://www.guiadepatas.com.br/" target="_blank" rel="noreferrer" className="pt-8 justify-end">Quero conhecer</Button>
                    </div>
                </div>
            </div>
        </section>
    )
}