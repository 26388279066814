import { FC } from "react";
import styled from "styled-components";
import { FooterSection } from "../footer";

const PrivacyPage: FC = () => {
  return (
    <>
      <StyledPrivacyPageWrapper className="break-words max-w-full px-80">
        <p className="font-normal text-base">
          No site e nos aplicativos da <strong>Petsaviors</strong>, são adotadas
          práticas que visam proporcionar ao usuário acesso às informações
          institucionais com privacidade e credibilidade. Este documento, que
          tem como objetivo apresentar a política de privacidade e os termos de
          uso do site e dos aplicativos da <strong>Petsaviors</strong>, pode ser
          alterado a qualquer momento. Desse modo, recomenda-se que seja
          consultado com regularidade e verificada a data de modificação, no
          trecho ”<strong>Atualizado em</strong>”.
        </p>

        <div className="mt-8">
          <h1 className="font-extrabold text-3xl text-amber-400">Tratamento de Dados</h1>
          <p className="font-normal text-base mt-4">
            Ao acessar este site e os aplicativos da <strong>Petsaviors</strong>, o
            usuário autoriza a coleta e a utilização de informações para os fins
            descritos nesta política de privacidade. Esses dados serão usados
            exclusivamente para os serviços prestados por essas ferramentas, de
            modo a tornar a navegação mais produtiva. A Code se compromete a não
            repassá-los para outras finalidades ou para terceiros. As
            informações de cadastro e senha são de uso exclusivo do internauta,
            por isso também não deve repassá-las para terceiros.
          </p>
        </div>

        <div className="mt-8">
          <h1 className="font-extrabold text-3xl text-amber-400">Uso de Cookies</h1>
          <p className="font-normal text-base mt-4">
            Em alguns aplicativos da <strong>Petsaviors</strong>, podem ser
            armazenados cookies, pequenos arquivos de texto que guardam
            determinados dados sobre o usuário no navegador. Por meio deles, são
            coletadas, automaticamente, informações sobre seu endereço IP, tipo
            de navegador, sistema operacional, páginas visitadas, duração da
            visita, entre outras. A utilização dos cookies também é necessária
            para o processamento de consulta em determinadas bases de dados,
            evitando a ação de mecanismos de pesquisa automáticos. Para que a
            consulta seja realizada, o navegador do usuário deve estar
            habilitado para gravação de cookies.
          </p>
        </div>

        <div className="mt-8">
          <h1 className="font-extrabold text-3xl text-amber-400">Termos de utilização</h1>
          <p className="font-normal text-base mt-4">
            O uso da logomarca da <strong>Petsaviors</strong> e da <strong>Guia de Patas</strong> é{" "}
            <strong>exclusivo</strong>, sendo vedada sua utilização para
            qualquer fim por terceiros. É permitida a reprodução total ou
            parcial, sem fins lucrativos, do conteúdo deste site e dos
            aplicativos da Petsaviors, desde que citada a fonte e mantida a
            integridade das informações. Também é autorizada a inserção de links
            do site da <strong>Petsaviors</strong>
            em outros sites desde que observado o seguinte:
          </p>

          <div className="mt-8">
            <div className="bg-purple-200/30 text-white rounded-md p-4">
              <span>
                A <strong className="text-amber-400"> Petsaviors</strong> não se
                responsabiliza por alterações promovidas nos links do seu site.
                <p>
                  Não é permitido a nenhum domínio utilizar, como página
                  inicial, o acesso direto à página inicial do site da{" "}
                  <strong className="text-amber-400"> Petsaviors</strong> e <strong className="text-amber-400"> Guia de Patas</strong>.
                </p>
              </span>
            </div>
          </div>

          <div className="mt-8 w-full text-right ml-auto italic">
            <p className="font-normal text-sm mt-2">
              Atualizado Em: 15/22/2023
            </p>
          </div>
        </div>
      </StyledPrivacyPageWrapper>
  
      <FooterSection />
    </>
  );
};

const StyledPrivacyPageWrapper = styled.div`
  font-family: "Inter", "Raleway", "Open sans", sans-serif;
  padding: 6.75rem 12rem 0rem 12rem;
  color: white;
  min-height: 100vh;

  @media (max-width: 1024px) {
    padding: 6.75rem 2rem 0rem 2rem;
  }

  @media (max-width: 1201px) and (screen) {
    padding: 6.75rem 16rem 0rem 16rem;
  }
`;

export default PrivacyPage;
